<template>
    <div>
    <div @click="$router.push('/weather')">
        <div class="weather-card" :style="{ width: cardWidth}">
            <h1 class="city-name" :style="{ fontSize: titleFontSize }">
                {{ i18n('Istanbul') }}
            </h1>
            <div class="weather-info">
                <div class="weather-description">
                    <p :style="{ fontSize: fontSize }">
                        {{ i18n('Today') }}
                    </p>
                    <p :style="{ fontSize: fontSize }">
                        {{ weatherDescription }}
                    </p>
                    <p :style="{ fontSize: fontSize }">
                       <strong>Avg.</strong> {{ high }}°C
                    </p>
                    <img
                        v-if="!loading && isOnline"
                        :src="weatherIconUrl"
                        :alt="weatherDescription"
                        :style="{ width: iconSize, height: iconSize }">
                    <div v-if="loading" class="loading-indicator"/>
                </div>
            </div>
        </div>
    </div>
    <div v-if="showNextDays" class="component-next-days">
        <h2 class="h2-title">
            {{ i18n('Next days') }}
        </h2>
        <div class="weather-forecast">
            <div
                class="weather-card-next-days"
                v-for="forecast in weatherForecast"
                :key="forecast.date">
                <p>{{ forecast.date }}</p>
                <div class="weather-info">
                    <div class="weather-description-next-days">
                        <p>{{ forecast.description }}</p>
                        <p><strong>Avg.</strong> {{ forecast.high }}°C</p>
                        <img
                            v-if="!loading"
                            :src="forecast.iconUrl"
                            :alt="forecast.description"
                            :style="{ width: forecastIconSize, height: forecastIconSize }">
                        <div v-if="loading" class="loading-indicator"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    inject: ['i18n', 'http', 'route'],
    props: {
        showNextDays: {
            type: Boolean,
            default: true,
        },
        showWeather: {
            type: Boolean,
            default: true,
        },
        fontSize: {
            type: String,
            default: '1rem',
        },
        titleFontSize: {
            type: String,
            default: '1.5rem',
        },
        cardWidth: {
            type: String,
            default: '80%',
        },
        showOnlyCurrentWeather: {
            type: Boolean,
            default: false,
        },
        iconSize: {
            type: String,
            default: '85px',
        },
        forecastIconSize: {
            type: String,
            default: '75px',
        },
    },
    data() {
        return {
            city: '',
            weatherIcon: '',
            weatherDescription: '',
            high: '',
            low: '',
            latitude: 40.994830,
            longitude: 29.107423,
            weatherForecast: [],
            loading: true,
        };
    },
    computed: {
        ...mapGetters('preferences', ['lang']),
        weatherIconUrl() {
            const weatherIcon = this.weatherIcon ? this.weatherIcon.slice(0, -1) : '';
            return weatherIcon ? `https://openweathermap.org/img/wn/${weatherIcon}d.png` : '';
        },
        isOnline() {
            return navigator.onLine;
        },
    },
    async created() {
        if (!navigator.onLine) {
            const local = JSON.parse(localStorage.getItem('weather'));
            this.weatherIcon = local.weatherIcon;
            this.weatherDescription = local.weatherDescription;
            this.weatherForecast = local.weatherForecast;
            this.temperature = local.temperature;

            this.city = local.city;
            this.latitude = local.latitude;
            this.longitude = local.longitude;
            this.loading = false;
        } else {
            let store = {};
            const { latitude } = this;
            const { longitude } = this;

            const weatherResponse = await fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&lang=${this.lang}&appid=${process.env.VUE_APP_WEATHER_KEY}&units=metric`,
            );
            const forecastResponse = await fetch(
                `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&lang=${this.lang}&appid=${process.env.VUE_APP_WEATHER_KEY}&units=metric`,
            );
            const reverseGeocodingResponse = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
            );
            const reverseGeocodingData = await reverseGeocodingResponse.json();
            this.city = reverseGeocodingData.address.city
                        || reverseGeocodingData.address.town
                        || reverseGeocodingData.address.village;
            this.latitude = latitude;
            this.longitude = longitude;

            const weatherData = await weatherResponse.json();
            const forecastData = await forecastResponse.json();
            const forecasts = forecastData.list.filter(forecast => {
                const forecastDate = new Date(forecast.dt_txt);
                const today = new Date();
                const differenceInDays = (forecastDate.getTime()
                            - today.getTime()) / (1000 * 3600 * 24);
                return differenceInDays >= 1 && differenceInDays <= 3;
            }).filter((forecast, index, self) => {
                const date = forecast.dt_txt.slice(0, 10);
                return !self.slice(0, index).some(f => f.dt_txt.slice(0, 10) === date);
            });
            this.weatherForecast = forecasts.map(forecast => ({
                date: forecast.dt_txt.slice(0, 10),
                description: forecast.weather[0].description,
                low: forecast.main.temp_min,
                high: forecast.main.temp_max,
                iconUrl: `https://openweathermap.org/img/wn/${forecast.weather[0].icon.slice(0, -1)}d.png`,
            }));

            this.weatherIcon = weatherData.weather[0].icon;
            this.weatherDescription = weatherData.weather[0].description;
            this.high = weatherData.main.temp_max;
            this.low = weatherData.main.temp_min;
            store = {
                city: this.city,
                latitude,
                longitude,
                weatherIcon: this.weatherIcon,
                weatherForecast: this.weatherForecast,
                weatherDescription: this.weatherDescription,
                high: weatherData.main.temp_max,
                min: weatherData.main.temp_min,
            };
            localStorage.setItem('weather', JSON.stringify(store));
            this.loading = false;
        }
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/enso.scss';
.weather-app {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title {
    color: $primary;
    font-size: 2.1rem;
    font-weight: bold;
    text-align: center;
}
.h2-title {
    text-align: center;
    margin-top: 1rem;
    font-weight: bold;
    color: $green;
    font-size: 1.8rem;
}
.weather-forecast {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}
.weather-card-next-days {
    max-width: 30%;
    width: 30%;
    height: 14.3rem;
    flex-wrap: wrap;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 0.8rem;
}
.weather-card {
    width: 80%;
    height: 50%;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 1rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.05);
    margin: auto;
}

.city-name {
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
}

.weather-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.weather-description {
    text-align: center;
    font-size: 1.7rem;
}

.weather-description-next-days {
    text-align: center;
    font-size: 1rem;
}

.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    width: 85px;
    font-size: 1.5rem;
    border: 4px solid $primary;
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
