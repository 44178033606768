<template>
    <div>
        <weather/>
    </div>
</template>

<script>
import Weather from '@components/weather/Weather.vue';

export default {
    name: 'Index',
    components: {
        Weather,
    },
    inject: ['i18n'],
};
</script>
<style scoped lang="scss">
@import '../../../sass/enso.scss';
</style>
